import {gql} from "@apollo/client/core";

export const CREATE_TENANT_CLICKANDMEET_MUTATION = gql`
mutation($user: ID, $businessName: String!, $mod: [TenantModuleDynamicZoneInput!]) {
  createTenant(
    input: {
      data: {
        Users: [$user]
        Name: $businessName
        Module: $mod
      }
    }
  ) {
    tenant {
      id
      Key
    }
  }
}`
