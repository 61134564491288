import React, {useState} from "react"
import {navigate} from "gatsby";
import {useMutation} from "@apollo/client";
import {RadioGroup} from '@headlessui/react'
import {getUser} from "../../services/auth";
import {CREATE_TENANT_CLICKANDMEET_MUTATION} from "../../graphql/tenantGraphQL";
import {useTranslation} from "react-i18next";
import logo from "../../images/logo.png";
import dancingVideo from "../../videos/dancing.mp4";
const plans = [
    {
        typename: "ComponentModulClickAndMeet",
        name: 'Click&Meet',
        priceMonthly: 5,
        priceYearly: 50,
        limit: 'Try it out for free the next 14 days.',
        selectable: true,
    },
    {
        typename: "ComponentModulClickAndEvent",
        name: 'Click&Event',
        priceMonthly: 7,
        priceYearly: 70,
        limit: 'Try it out for free the next 14 days.',
        selectable: true,
    },
    {
        typename: "ComponentModulClickAndFood",
        name: 'Food',
        priceMonthly: 0,
        priceYearly: 0,
        limit: 'coming soon',
        selectable: false
    },
    {
        typename: "ComponentModulClickAndCollect",
        name: 'Click&Collect',
        priceMonthly: 0,
        priceYearly: 0,
        limit: 'coming soon',
        selectable: false
    },
    {
        typename: "ComponentModulPage",
        name: 'Page Builder',
        priceMonthly: 0,
        priceYearly: 0,
        limit: 'coming soon',
        selectable: false
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NewTenant() {
    const { t } = useTranslation()
    const [
        createTenant,
        {loading: mutationLoading, error: mutationError},
    ] = useMutation(CREATE_TENANT_CLICKANDMEET_MUTATION)

    const [businessName, setBusinessName] = useState("")
    const [selected, setSelected] = useState(plans[0])


    const handleRegister = async (event) => {
        // Block native form submission.
        event.preventDefault()

        createTenant({
            variables: {
                user: getUser().id,
                businessName: businessName,
                mod: [{"__typename":selected.typename, "Active":false}]
            },
        }).then((res) => {

            switch (selected.typename) {
                case 'ComponentModulClickAndMeet':
                    navigate("/meet/setup",{
                        state: { tenant: res.data.createTenant.tenant },
                    })
                    break
                default:
                    navigate("/")
                    break
            }
        })
    }

    console.log(mutationLoading)
    console.log(mutationError)

    return (
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src={logo}
                            alt="ClickAnd"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('tenant.new.title')}</h2>
                        <p className="mt-2 text-sm text-gray-600">
                            {t('tenant.new.subtitle')}

                        </p>
                    </div>

                    <div className="mt-8">

                        <div className="mt-6">
                            <form
                                onSubmit={handleRegister}
                                className="space-y-6">
                                <div>
                                    <label htmlFor="business" className="block text-sm font-medium text-gray-700">
                                        {t('input.business.name')}
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="business"
                                            name="business"
                                            type="text"
                                            autoComplete="business"
                                            required
                                            onChange={(e) => {
                                                setBusinessName(e.target.value)
                                            }}
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <RadioGroup value={selected} onChange={setSelected}>
                                    <RadioGroup.Label className="block text-sm font-medium text-gray-700">
                                        {t('tenant.new.select.module')}
                                    </RadioGroup.Label>
                                    <div className="relative bg-white rounded-md -space-y-px mt-1">
                                        {plans.map((plan, planIdx) => (
                                            <RadioGroup.Option
                                                key={plan.name}
                                                value={plan}
                                                disabled={!plan.selectable}
                                                className={({checked}) =>
                                                    classNames(
                                                        planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                                        planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                                        checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                                                        'relative border p-4 flex flex-col cursor-pointer focus:outline-none'
                                                    )
                                                }
                                            >
                                                {({active, checked}) => (
                                                    <>
                                                        <div className="flex items-center text-sm">
                  <span
                      className={classNames(
                          checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                          'h-4 w-4 rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5"/>
                  </span>
                                                            <RadioGroup.Label as="span"
                                                                              className="ml-3 font-medium text-gray-900">
                                                                {plan.name}
                                                            </RadioGroup.Label>
                                                        </div>

                                                        {plan.selectable ? (
                                                            <RadioGroup.Description className="ml-6 pl-1 text-sm">
                                                                <span className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'font-medium')}>
                                                                    {plan.priceMonthly} € / mo
                                                                </span>{' '}
                                                                <span
                                                                    className={checked ? 'text-indigo-700' : 'text-gray-500'}>
                                                                    ({plan.priceYearly} € / yr)
                                                                </span>
                                                            </RadioGroup.Description>
                                                        ) : ''}

                                                        <RadioGroup.Description
                                                            className={classNames(
                                                                checked ? 'text-indigo-700' : 'text-gray-500',
                                                                'ml-6 pl-1 text-sm'
                                                            )}
                                                        >
                                                            {plan.limit}
                                                        </RadioGroup.Description>
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        ))}
                                    </div>
                                </RadioGroup>

                                <div>
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {t('tenant.new.button')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <video
                    className="absolute inset-0 h-full w-full object-cover"
                    autoPlay
                    loop
                    playsInline
                    muted>
                    <source
                        src={dancingVideo}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
